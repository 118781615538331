import { useEffect, useState } from "react";
import { Pet } from "../../../common/types";
import { PetStatCard } from "../cards/PetStatCard";
import { OWNERS, formatTime } from "../../helpers/helpers";

export function TopPetsLeaderboard({
  topPets,
}: {
  topPets: Pet[];
  walletAddress: string;
}) {
  const [timeAttackables, setTimeAttackables] = useState<string[]>([]);
  const [sortedPets, setSortedPets] = useState<Pet[]>([]);

  useEffect(() => {
    const sorted = [...topPets].sort((a, b) => b.score - a.score);
    setSortedPets(sorted);
  }, [topPets]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeAttackables(topPets.map((pet) => formatTime(pet)));
    }, 300);
    return () => clearInterval(interval);
  }, [topPets]);

  return (
    <div className="flex">
      <ul className="mb-2 flex h-screen w-[90%] flex-col">
        {sortedPets.map((pet, index) => (
          <li key={pet.id} className="flex flex-col">
            <div className="flex flex-col">
              <PetStatCard pet={pet} />
            </div>
            <div className="flex flex-row justify-center ">
              <div className="mb-3 ml-10 flex">
                <img
                  src="/imgs/rank.svg"
                  alt=""
                  className="ml-2 mt-1 h-5 w-5"
                />
                <div className="ml-1 mt-1"> {index + 1}</div>
              </div>
                <div className="ml-2 flex">
                  <div className="mr-2 mt-[.2rem]"> • </div>
                  <div className="mt-1 flex h-[25px] w-[75px] flex-row items-center justify-center rounded-lg border-[1.5px] border-brown bg-white p-1 [box-shadow:inset_0_-2px_#342e2e]">
                    {timeAttackables[index]}
                  </div>
                </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
