import { PGoldPrice, FpPrice, EthPrice } from "../../../common/types";
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Line,
  LineChart,
  Tooltip,
} from "recharts";
import {
  formatDayTime,
  calculateTicks,
  formatCurrency,
} from "../../helpers/helpers";
import { useState } from "react";
import { secondsDict } from "../utils/ChartUtils";

export function Button({
  text,
  selected,
  setSelected,
}: {
  text: string;
  selected: boolean;
  setSelected: (text: string) => void;
}) {
  return (
    <button
      className={`rounded-lg border-[1px] border-brown px-2 py-1 ${
        selected ? "bg-brown text-white" : "bg-white text-brown"
      }`}
      onClick={() => setSelected(text)}
    >
      {text}
    </button>
  );
}

export function PGoldChart({
    pGoldPrices,
    fpPrice,
  }: {
    pGoldPrices: PGoldPrice[];
    fpPrice: FpPrice;
  }) {
    const [selectedTimeRange, setSelectedTimeRange] = useState("1D");
    const [selectedCurrency, setSelectedCurrency] = useState("DEFAULT");
    const [isUSDToggled, setIsUSDToggled] = useState(false); 
  
    const data = pGoldPrices.filter(
      (price) => price.timestamp > Date.now() / 1000 - secondsDict[selectedTimeRange]
    );
  
    const getPriceInSelectedCurrency = (priceInFP: number) => {
      if (selectedCurrency === "USD") {
        return (1 / priceInFP) * fpPrice.fp_price;
      }
      if (selectedCurrency === "FP") {
        return 1 / priceInFP;
      }
      return priceInFP;
    };
  
    return (
      <div className="my-2 ml-4 mr-4 flex w-[80%] flex-col items-start justify-center rounded-lg border-2 border-brown bg-white p-4 text-sm [box-shadow:inset_0_-2px_#342e2e]">
        <div className="flex w-full justify-between">
          <div className="flex flex-row">
            <div className="mb-4 font-sans text-xl">
              $pGold
            </div>
            <div className="mb-4 ml-1 space-x-1 flex flex-row font-sans text-sm">
              <div className="ml-2 mr-2">
                <Button
                  text="$pG/$FP"
                  selected={selectedCurrency === "DEFAULT"}
                  setSelected={() => setSelectedCurrency("DEFAULT")}
                />
              </div>
              <Button
                text={isUSDToggled ? "$USD/$pG" : "$FP/$pG"} 
                selected={selectedCurrency === "FP" || selectedCurrency === "USD"}
                setSelected={() => {
                  setSelectedCurrency("FP");
                  setIsUSDToggled(false);
                }}
              />
              {(selectedCurrency === "FP" || selectedCurrency === "USD")  && (
              <div className="flex flex-row items-center">
                <div
                  className={`relative h-6 w-10 ml-2 rounded-full border-2 border-brown bg-white cursor-pointer ${
                    isUSDToggled ? "bg-green" : ""
                  }`}
                  onClick={() => {
                    const chartToggle = !isUSDToggled;
                    setIsUSDToggled(chartToggle);
                    setSelectedCurrency(chartToggle ? "USD" : "FP");
                  }}
                >
                  <div
                    className={`h-4 w-4 m-[.135rem] rounded-full bg-brown ${
                      isUSDToggled ? "transform translate-x-full" : ""
                    }`}
                  ></div>
                </div>
              </div>
            )}
            </div>
          </div>
          <div className="mb-4 space-x-1 font-sans text-sm">
            <Button
              text="1D"
              selected={selectedTimeRange === "1D"}
              setSelected={setSelectedTimeRange}
            />
            {/* 
            <Button
              text="7D"
              selected={selectedTimeRange === "7D"}
              setSelected={setSelectedTimeRange}
            />
            <Button
              text="1M"
              selected={selectedTimeRange === "1M"}
              setSelected={setSelectedTimeRange}
            />
            */}
          </div>
        </div>
        <ResponsiveContainer width="100%" className="ml-1" height={200}>
          <LineChart data={data}>
            <XAxis
              dataKey="timestamp"
              type="number"
              tickFormatter={formatDayTime}
              ticks={calculateTicks(data)}
              domain={["dataMin", "dataMax"]}
              stroke="#342e2e"
            />
            <YAxis
              dataKey={(price) => getPriceInSelectedCurrency(price.pgold_price)}
              stroke="#342e2e"
              domain={["auto", "auto"]}
              tickFormatter={formatCurrency}
            />
            <Line
              type="monotone"
              dataKey={(price) => getPriceInSelectedCurrency(price.pgold_price)}
              stroke="#342e2e"
              dot={false}
            />
            <Tooltip labelFormatter={formatDayTime} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }