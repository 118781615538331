import { useState } from "react";
import { Pet } from "../../../common/types";
import { Meter } from "../utils/Meter";
import { formatTimerCardTime, getTrainingStartAndEndTime } from "../../helpers/helpers";

export function PetTimerCard({ pet, walletAddress }: { pet: Pet; walletAddress: string }) {
  const [showTooltip, setShowTooltip] = useState(false);
  const [isNextGacha, setIsNextGacha] = useState(true);

  const toggleTooltip = () => setShowTooltip(!showTooltip);

  const { trainingStartSec, trainingEndSec } = getTrainingStartAndEndTime(pet);

  const timeUntilStarving = formatTimerCardTime(pet.timeUntilStarving, "starving");
  const timeUntilAttack = formatTimerCardTime(pet.timeUntilAttack, "attack");
  const timeUntilWheel = formatTimerCardTime(pet.timeUntilWheel, "wheel");
  const timeUntilShieldExpires = formatTimerCardTime(pet.shieldExpires, "shield");
  const timeTrainingEnds = formatTimerCardTime(trainingEndSec, "training");

  const toggleGachaValue = () => setIsNextGacha(!isNextGacha);

  return (
    <div className="mb-2 ml-4 mr-4 mt-2 flex h-[15.75rem] w-[21.5rem] flex-row rounded-lg border-2 border-brown bg-white p-3 [box-shadow:inset_0_-2px_#342e2e]">
      <div className="flex w-[35%] flex-col">
        <div className="h-16 w-16 overflow-hidden rounded-lg border-2 border-brown bg-green">
          <span className="absolute ml-[1.85rem] -translate-y-1/3 translate-x-1/3 transform rounded-full border border-brown bg-green px-2 py-[.1rem] text-xs ring-1 ring-inset ring-brown [box-shadow:inset_0_-2px_#342e2e]">
            lv. {pet.level}
          </span>
          <a href={`https://frenpet.xyz/pet/${pet.id}`} target="_blank" rel="noreferrer">
            <img
              src={pet.encodedSvgUrl}
              alt={"no pet img"}
              className="h-full w-full object-cover"
            />
          </a>
        </div>
        <div className="ml-1 mt-2 flex flex-col items-start">
          <h2 className="text-md">id #{pet.id}</h2>
          <h2 className="text-md mt-4 max-w-24 overflow-hidden text-ellipsis whitespace-nowrap">
            {pet.name}
          </h2>
          <h2 className="text-md mt-3 flex max-w-24 overflow-hidden text-ellipsis whitespace-nowrap">
            <img src="/imgs/piggy-bank.svg" alt="" className="mr-1 mt-[.7rem] h-4 w-4" />
            <div className="mt-2"> {pet.score.toFixed(0)} </div>
          </h2>
          <h2 className="text-md mt-4 flex max-w-24 overflow-hidden text-ellipsis whitespace-nowrap" onClick={toggleGachaValue}>
            {pet.availableGacha > 0 && (
              <span className="absolute -translate-y-[30%] translate-x-[10%] transform rounded-full border border-brown bg-green px-[.35rem] py-[.05rem] text-xs ring-1 ring-inset ring-brown [box-shadow:inset_0_-2px_#342e2e]">
                {pet.availableGacha}
              </span>
            )}
            <img src="/imgs/slot.svg" alt="" className="mr-1 mt-[.47rem] h-4 w-4" />
            <div className="mt-1"> {isNextGacha ?  pet.nextGacha.toFixed(0) : pet.gachaEfficiency?.toFixed(2)}  </div>
          </h2>
        </div>
      </div>
      <div className="mr-3 border border-brown"></div>
      <div className="flex w-[65%] flex-col justify-start">
        <div className="flex h-full w-full flex-col items-start justify-between">
          {showTooltip && (
            <div className="top-50 absolute z-20 mt-2 -translate-x-1/2 transform rounded border-2 border-brown bg-white px-2 py-1 text-brown [box-shadow:inset_0_-2px_#342e2e]">
              <div className="flex">
                <img src="/imgs/skull.svg" alt="" className=" ml-1 mr-1 mt-[0.22rem] h-4 w-4" />
                • {timeUntilStarving}
              </div>
              <div className="mt-2 flex">
                <img
                  src="/imgs/sword.svg"
                  alt=""
                  className=" z-10 mt-[0.05rem] h-6 w-6 scale-x-[-1] transform"
                />
                • {timeUntilAttack}
              </div>
              <div className="mt-2 flex">
                <img
                  src="/imgs/wheelspin.svg"
                  alt=""
                  className=" mr-1 mt-[0.11rem] h-5 w-5 scale-x-[-1] transform"
                />
                • {timeUntilWheel}
              </div>
              <div className="mt-2 flex">
                <img
                  src="/imgs/shield-solid.svg"
                  alt=""
                  className=" mr-1 mt-1 h-4 w-5 scale-x-[-1] transform"
                />
                • {timeUntilShieldExpires}
              </div>
              <div className="mt-2 flex">
                <img
                  src="/imgs/weight.svg"
                  alt=""
                  className=" mr-1 mt-1 h-4 w-5 scale-x-[-1] transform"
                />
                • {timeTrainingEnds}
              </div>
            </div>
          )}
          <Meter
            startSec={pet.timeUntilStarvingStart}
            endSec={pet.timeUntilStarving}
            imgSrc="/imgs/apple.svg"
            imgClass="h-[1.3rem] w-[1.3rem] ml-[0.12rem]"
            isDepleting={true}
            showTooltip={false}
            walletAddress={walletAddress}
            reverseTooltip={true}
            onClick={toggleTooltip}
          />
          <Meter
            startSec={pet.lastAttackUsed}
            endSec={pet.timeUntilAttack}
            imgSrc="/imgs/sword.svg"
            imgClass="h-6 w-6 scale-x-[-1] transform"
            isDepleting={false}
            showTooltip={false}
            walletAddress={walletAddress}
            reverseTooltip={true}
          />
          <Meter
            startSec={pet.lastWheelSpin}
            endSec={pet.timeUntilWheel}
            imgSrc="/imgs/wheelspin.svg"
            imgClass="h-6 w-6"
            isDepleting={false}
            showTooltip={false}
            walletAddress={walletAddress}
            reverseTooltip={true}
          />
          <Meter
            startSec={trainingStartSec}
            endSec={trainingEndSec}
            imgSrc="/imgs/weight.svg"
            imgClass="h-[1.3rem] w-[1.3rem] ml-[.1rem]"
            isDepleting={false}
            showTooltip={false}
            walletAddress={walletAddress}
            reverseTooltip={true}
            isTraining={pet.isTraining}
            trainingEndSec={trainingEndSec}
          />
          <Meter
            startSec={pet.shieldStart}
            endSec={pet.shieldExpires}
            imgSrc="/imgs/shield-solid.svg"
            imgClass="h-[1.3rem] w-[1.3rem] ml-[.1rem]"
            isDepleting={true}
            showTooltip={false}
            walletAddress={walletAddress}
          />
        </div>
      </div>
    </div>
  );
}
