import {
  GetPetsResponse,
  Pet,
  PetRewards,
  PetScores,
  Reward,
  Score,
  Totals,
} from "../../common/types";
import {
  getEthBalance,
  getFpBalance,
  getPgoldBalance,
} from "./utils";

export const WINS_PER_MONSTER = 40;
export const MAX_MONSTERS = 18;

export const OWNERS = [
  "0xa2ff5FA68f7611d425Fe6E0AC5021f4DA138F887",
  "0xA9d85386050eF91d65Da21B6D0362f757c6b4ef2",
  "0x96abF70Bca92B7622981881D5De2001bf20c4248",
];

export const blacklistedAddresses = new Set<string>([
  "0xA3C1b8A95B937E78F8d6FB1d68A58C443946c795",
  "0x040ba8d0E4870ac54e4560E18B2666E3BF0B055C",
  "0x33bA18618E90a9Cf5BfE96DBDe6eFe5FC4e16Bf4",
  "0xa2F6Cc2aD8Ee16bb319723884C966587e5980F09",
  "0xDdACf371D815bd475379d86f8Eb9Eb5034b55298"
]);


export const CP = ["0xa2ff5FA68f7611d425Fe6E0AC5021f4DA138F887"];

export function formatTime(pet: Pet) {
  if (OWNERS.includes(pet.owner)) {
    return "fren";
  }
  const seconds = pet.timeUntilAttackable;
  const secondsUntilAttackable = seconds - Math.floor(Date.now() / 1000);
  if (secondsUntilAttackable < 0) {
    return "bonk";
  }

  const hours = Math.floor((secondsUntilAttackable % (24 * 60 * 60)) / (60 * 60));
  const minutes = Math.floor((secondsUntilAttackable % (60 * 60)) / 60);
  const secs = secondsUntilAttackable % 60;

  let timeString = "";

  if (hours > 0) {
    timeString += `${hours.toString().padStart(2, "0")}:`;
  }

  if (hours > 0 || minutes > 0) {
    timeString += `${minutes.toString().padStart(2, "0")}:`;
  } else if (hours === 0 && minutes === 0) {
    timeString += "0:";
  }

  timeString += secs.toString().padStart(2, "0");

  return timeString;
}

export function formatMinSec(seconds: number): string {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}m ${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}s`;
}


export function formatDayTime(timestamp: number): string {
  const date = new Date(timestamp * 1000);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12;
  const minutesStr = minutes < 10 ? "0" + minutes : minutes;

  return `${month}/${day} ${hours}:${minutesStr} ${ampm}`;
}

interface TimestampedItem {
  timestamp: number;
}

export function calculateTicks<T extends TimestampedItem>(data: T[]): number[] {
  let ticks = data.map((item) => item.timestamp);
  ticks = [...new Set(ticks)];
  ticks.sort((a, b) => a - b);

  if (ticks.length > 1) {
    ticks.shift();
  }

  return ticks;
}

export const formatTimerCardTime = (endTime: number, type: string) => {
  const now = Math.floor(Date.now() / 1000);
  const secondsLeft = endTime - now;
  const h = Math.floor(secondsLeft / 3600);
  const m = Math.floor((secondsLeft % 3600) / 60);
  if (h > 0) {
    return `${h} hour${h !== 1 ? "s" : ""}`;
  } else if (m > 0) {
    return `${m} minute${m !== 1 ? "s" : ""}`;
  } else if (secondsLeft > 0) {
    return `${secondsLeft} second${secondsLeft !== 1 ? "s" : ""}`;
  } else {
    switch (type) {
      case "starving":
        return "dead";
      case "attack":
        return "bonk";
      case "wheel":
        return "Spin";
      case "shield":
        return "no shield";
      case "training":
        return "not training";
      default:
        return "Ready";
    }
  }
};

export function sortPetsOld(pets: Pet[], primarySortKey: keyof Pet, sortOrder: "asc" | "desc"): Pet[] {
  const secondarySortKey: keyof Pet = 'petType';

  return [...pets].sort((a, b) => {
    const primaryA = a[primarySortKey];
    const primaryB = b[primarySortKey];
    const secondaryA = a[secondarySortKey];
    const secondaryB = b[secondarySortKey];

    if (primaryA !== undefined && primaryB !== undefined) {
      if (primaryA < primaryB) return sortOrder === "asc" ? -1 : 1;
      if (primaryA > primaryB) return sortOrder === "asc" ? 1 : -1;
    }

    if (secondaryA !== undefined && secondaryB !== undefined) {
      if (secondaryA < secondaryB) return sortOrder === "asc" ? -1 : 1;
      if (secondaryA > secondaryB) return sortOrder === "asc" ? 1 : -1;
    }

    return 0;
  });
}

export function calcCarrotBalance(carrots: number, timestamp: number): number {
  return carrots + (Date.now() / 1000 - timestamp) * 0.0002;
}

export const formatCurrency = (value: number) => `$${value.toFixed(2)}`;

export const refreshPetsAndTotals = async (
  walletAddress: string,
  sortKey: keyof Pet,
  sortOrder: "asc" | "desc",
  setPets: React.Dispatch<React.SetStateAction<Pet[]>>,
  setRewards: React.Dispatch<React.SetStateAction<Reward[]>>,
  setScores: React.Dispatch<React.SetStateAction<Score[]>>,
  setTotals: React.Dispatch<React.SetStateAction<Totals>>,
  setPetRewards: React.Dispatch<React.SetStateAction<PetRewards>>,
  setPetScores: React.Dispatch<React.SetStateAction<PetScores>>,
  setEthBalance: React.Dispatch<React.SetStateAction<string>>,
  setPgoldBalance: React.Dispatch<React.SetStateAction<string>>,
  setFpBalance: React.Dispatch<React.SetStateAction<string>>,
  data: GetPetsResponse,
) => {
  const totalRewards = data.totalRewards;
  const totalPets = data.totalPets;
  const totalScore = data.totalScore;
  const totalFpOwed = data.totalFpOwed;
  const totalFpPerSecond = data.totalFpPerSecond;
  const totalStars = data.totalStars;
  const totalMonsters = data.totalMonsters;
  setPets(sortPets(data.pets, sortKey, sortOrder));
  setRewards(data.rewards);
  setScores(data.scores);
  setPetRewards(data.petRewards);
  setPetScores(data.petScores);
  setTotals({
    totalRewards,
    totalFpOwed,
    totalFpPerSecond,
    totalPets,
    totalScore,
    totalStars,
    totalMonsters,
  });
  try {
    //console.log("helpers refresh pets and totals...")
    const balance = await getEthBalance(walletAddress);
    setEthBalance(parseFloat(balance).toFixed(4));
    const pGoldBalance = await getPgoldBalance(walletAddress);
    setPgoldBalance(parseFloat(pGoldBalance).toFixed(0));
    const fpBalance = await getFpBalance(walletAddress);
    setFpBalance(parseFloat(fpBalance).toFixed(2));
  } catch (error) {
    console.error("Failed to fetch Ethereum balance:", error);
  }
};

export const calculateStaked = (fpPerSecond: number): number => {
  const perSecondRates: { [key: number]: number } = {
    1000: 1.3699 / 3600,
    400: 0.5479 / 3600,
    100: 0.1371 / 3600,
    30: 0.0411 / 3600,
    10: 0.0137 / 3600,
  };

  let totalReturnPerSecond = Number(fpPerSecond);

  let totalStaked = 0;

  for (let amount of [1000, 400, 100, 30, 10]) {
    const rate = perSecondRates[amount];
    while (totalReturnPerSecond >= rate) {
      totalStaked += amount;
      totalReturnPerSecond -= rate;
    }
  }

  return totalStaked;
};

export function calculateMonsterProgress(
  monstersCaught: number,
  petWins: number,
  nextMonsterWins: number,
): { winsToNextMonster: number | string; collectableMonsters: number; pendingMonsters: number } {
  const collectableMonsters = Math.floor(petWins / WINS_PER_MONSTER);

  if (petWins >= nextMonsterWins) {
    nextMonsterWins = (Math.floor(petWins / WINS_PER_MONSTER) + 1) * WINS_PER_MONSTER;
  }

  let pendingMonsters =
    collectableMonsters > monstersCaught ? collectableMonsters - monstersCaught : 0;
  if (monstersCaught >= MAX_MONSTERS) {
    return {
      winsToNextMonster: "max",
      collectableMonsters,
      pendingMonsters: 0,
    };
  }

  const winsToNextMonster = nextMonsterWins - petWins;
  const winsF = 40 - winsToNextMonster;
  if (pendingMonsters + monstersCaught > MAX_MONSTERS) {
    pendingMonsters = MAX_MONSTERS - monstersCaught;
  } else if (monstersCaught > 1 && pendingMonsters === 1) {
    if (petWins > 400 && petWins - winsF >= 400) {
      pendingMonsters = 1;
    }
  }
  if (monstersCaught + pendingMonsters > MAX_MONSTERS) {
    return {
      winsToNextMonster: "fight",
      collectableMonsters,
      pendingMonsters: 0,
    };
  }

  return {
    winsToNextMonster: winsToNextMonster > 0 ? 40 - winsToNextMonster : 0,
    collectableMonsters,
    pendingMonsters,
  };
}

export function getTrainingStartAndEndTime(pet: Pet) {
  const now = Math.floor(Date.now() / 1000);

  let endTime = pet.atkTrainingEndsTimestamp;
  let level = pet.atkLevel;

  if (pet.defTrainingEndsTimestamp > now) {
    endTime = pet.defTrainingEndsTimestamp;
    level = pet.defLevel;
  }

  if (endTime <= now) {
    return { trainingStartSec: now, trainingEndSec: now, timeLeft: 0 };
  }

  const levelStartTime = 30 * 60 * (level + 1);

  const trainingStartSec = endTime - levelStartTime;
  const trainingEndSec = endTime;

  return { trainingStartSec, trainingEndSec };
}

export function sortPets(
  pets: Pet[],
  primarySortKey: keyof Pet,
  sortOrder: "asc" | "desc"
): Pet[] {
  const secondarySortKey: keyof Pet = 'petType';
  const now = Math.floor(Date.now() / 1000);

  return [...pets].sort((a, b) => {
    if (primarySortKey === 'atkTrainingEndsTimestamp' || primarySortKey === 'defTrainingEndsTimestamp') {
      const atkTrainingA = (a.atkTrainingEndsTimestamp > now) ? a.atkTrainingEndsTimestamp : 0;
      const atkTrainingB = (b.atkTrainingEndsTimestamp > now) ? b.atkTrainingEndsTimestamp : 0;
      const defTrainingA = (a.defTrainingEndsTimestamp > now) ? a.defTrainingEndsTimestamp : 0;
      const defTrainingB = (b.defTrainingEndsTimestamp > now) ? b.defTrainingEndsTimestamp : 0;

      const aIsNotTraining = atkTrainingA === 0 && defTrainingA === 0;
      const bIsNotTraining = atkTrainingB === 0 && defTrainingB === 0;

      if (aIsNotTraining && !bIsNotTraining) return 1;
      if (!aIsNotTraining && bIsNotTraining) return -1;

      if (primarySortKey === 'atkTrainingEndsTimestamp') {
        if (atkTrainingA !== atkTrainingB) {
          return sortOrder === "asc" ? atkTrainingA - atkTrainingB : atkTrainingB - atkTrainingA;
        }
        if (defTrainingA !== defTrainingB) {
          return sortOrder === "asc" ? defTrainingA - defTrainingB : defTrainingB - defTrainingA;
        }
      }

      if (primarySortKey === 'defTrainingEndsTimestamp') {
        if (defTrainingA !== defTrainingB) {
          return sortOrder === "asc" ? defTrainingA - defTrainingB : defTrainingB - defTrainingA;
        }
        if (atkTrainingA !== atkTrainingB) {
          return sortOrder === "asc" ? atkTrainingA - atkTrainingB : atkTrainingB - atkTrainingA;
        }
      }
    }

    const primaryA = a[primarySortKey];
    const primaryB = b[primarySortKey];
    const secondaryA = a[secondarySortKey];
    const secondaryB = b[secondarySortKey];

    if (primaryA !== undefined && primaryB !== undefined) {
      if (primaryA < primaryB) return sortOrder === "asc" ? -1 : 1;
      if (primaryA > primaryB) return sortOrder === "asc" ? 1 : -1;
    }

    if (secondaryA !== undefined && secondaryB !== undefined) {
      if (secondaryA < secondaryB) return sortOrder === "asc" ? -1 : 1;
      if (secondaryA > secondaryB) return sortOrder === "asc" ? 1 : -1;
    }

    return 0;
  });
}

export function getProgress(startSec: number, endSec: number, isDepleting: boolean) {
  const now = Math.floor(Date.now() / 1000);
  const total = endSec - startSec;
  let timeElapsed = now - startSec;
  if (timeElapsed > total) {
    timeElapsed = total;
  }

  const progress = (timeElapsed / total) * 100;
  return isDepleting ? 100 - progress : progress;
}

export function getTimeSince(timestamp: number) {
  const now = Math.floor(Date.now() / 1000);
  const timeElapsed = Math.abs(now - timestamp);
  const days = Math.floor(timeElapsed / 86400);
  const hours = Math.floor((timeElapsed % 86400) / 3600);
  const minutes = Math.floor((timeElapsed % 3600) / 60);
  const seconds = timeElapsed % 60;
  return `${days}d ${hours}h ${minutes}m ${seconds}s`;
}

export function formatPrice(price: number): string {
  const formattedPrice = parseFloat(price.toFixed(3));
  
  return formattedPrice.toString();
}