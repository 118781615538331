import { GasPrice, Reward, FpPrice, Score, PetRewards, PetScores, EthPrice, PGoldPrice } from "../../common/types";
import { GasChart } from "../components/charts/GasChart";
import { RewardChart } from "../components/charts/RewardChart";
import { FpChart } from "../components/charts/FpChart";
import { ScoreChart } from "../components/charts/ScoreChart";
import { useState } from "react";
import { EthChart } from "../components/charts/EthChart";
import { PGoldChart } from "../components/charts/PGoldChart";

function ToggleButton({ value }: { value: boolean }) {
  // Slider button
  return (
    <div
      className={`flex h-4 w-7 flex-row items-center rounded-full border-2 border-brown bg-white ${
        value ? "justify-end" : "justify-start"
      }`}
    >
      <div className={`h-2 w-2 m-1 rounded-full bg-brown`}></div>
    </div>
  );
}

export function Charts({
  gasPrices,
  rewards,
  fpPrices,
  scores,
  petRewards,
  petScores,
  ethPrices,
  pGoldPrices,
}: {
  gasPrices: GasPrice[];
  rewards: Reward[];
  fpPrices: FpPrice[];
  ethPrices: EthPrice[];
  scores: Score[];
  petRewards: PetRewards;
  petScores: PetScores;
  pGoldPrices: PGoldPrice[];
}) {
  const [showPets, setHideTotal] = useState(false);

  return (
    <div className="flex w-full flex-col items-center justify-center">
      <div
        className="flex items-center justify-center cursor-default"
        onClick={() => setHideTotal(!showPets)}
      >
        <p className="text-sm font-sans mr-2">Show Pets</p> <ToggleButton value={showPets} />
      </div>
      <RewardChart rewards={rewards} petRewards={petRewards} showPets={showPets} />
      <ScoreChart scores={scores} petScores={petScores} showPets={showPets} />
      <GasChart gasPrices={gasPrices} />
      <FpChart fpPrices={fpPrices} />
      <PGoldChart pGoldPrices={pGoldPrices} fpPrice={fpPrices[fpPrices.length - 1]} /> 
      <EthChart ethPrices={ethPrices} />
    </div>
  );
}
