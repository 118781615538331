import { useEffect, useState } from "react";
import { Pet } from "../../../common/types";
import { PetStatCard } from "../cards/PetStatCard";

export function TopCatsLeaderboard({
  topCats,
  totalCats,
}: {
  topCats: Pet[];
  totalCats: number;
}) {
  const [sortedCats, setSortedCats] = useState<Pet[]>([]);
  const [filteredCatCount, setFilteredCatCount] = useState<number>(totalCats);

  useEffect(() => {
    const filteredCats = topCats.filter((pet) => pet.petType === "acat");
    const sorted = filteredCats.sort((a, b) => b.score - a.score);
    setSortedCats(sorted);
    setFilteredCatCount(filteredCats.length);
  }, [topCats]);

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex flex-row justify-center items-center">
      <div className="text-md flex flex-row justify-center items-center text-brown">
          Cats Alive • {filteredCatCount}
        </div>
      </div>
      <ul className="mb-2 flex h-screen w-[90%] flex-col">
        {sortedCats.map((pet, index) => (
          <li key={pet.id} className="flex flex-col">
            <div className="flex flex-col">
              <PetStatCard pet={pet} />
            </div>
            <div className="flex flex-row justify-center ">
              <div className="mb-3 ml-10 flex">
                <img
                  src="/imgs/rank.svg"
                  alt=""
                  className="ml-2 mt-1 h-5 w-5"
                />
                <div className="ml-1 mt-1"> {index + 1}</div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
