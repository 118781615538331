import { useEffect, useState } from "react";

export function EmptyState({ isLoading }: { isLoading: boolean }) {
  const [loadingText, setLoadingText] = useState(".");
  const [penguinSrc, setPenguinSrc] = useState("/imgs/fp_penguin_idle.png");

  useEffect(() => {
    if (isLoading) {
      const ellipsisInterval = setInterval(() => {
        setLoadingText((prev) => {
          return prev === "" ? "." : prev === "." ? ".." : prev === ".." ? "..." : "";
        });
      }, 500);

      const penguinInterval = setInterval(() => {
        setPenguinSrc((prev) =>
          prev === "/imgs/fp_penguin_idle.png"
            ? "/imgs/fp_penguin.png"
            : "/imgs/fp_penguin_idle.png"
        );
      }, 500);

      return () => {
        clearInterval(ellipsisInterval);
        clearInterval(penguinInterval);
      };
    }
  }, [isLoading]);

  return (
    <div className="flex flex-col items-center justify-center h-full w-full">
      <div className="flex flex-col items-center justify-center mt-2 rounded-lg border-2 border-brown bg-white p-3 [box-shadow:inset_0_-2px_#342e2e] w-[22.5rem]">
        <div className="flex flex-col items-center justify-center">
          <a href={`https://frenpet.xyz/`} target="_blank" rel="noreferrer">
            <img src={penguinSrc} alt="Loading Penguin" className="h-32 w-36" />
          </a>
          <h1 className="font-bold">Fren Pet Analytica</h1>
          <div className="flex flex-row ml-1">
            <img src="/imgs/paw.svg" alt="" className="mr-[0.7rem] mt-2 h-4 w-4" />
            {isLoading ? (
              <h2 className="text-sm font-semibold mt-2">
                Loading your Fren Pet data
                <div className="pr-1 text-left min-w-5 inline-block">{loadingText}</div>
              </h2>
            ) : (
              <h2 className="text-sm font-semibold mt-2">Your Fren Pet data</h2>
            )}
            <img src="/imgs/paw.svg" alt="" className="mr-[0.3rem] ml-1 mt-2 h-4 w-4" />
          </div>
        </div>
        {isLoading ? (
          <p className="flex flex-row text-sm mt-3 text-center"></p>
        ) : (
          <p className="text-sm mt-3 text-center">
            To view pets, stats, and balances, enter your wallet address
          </p>
        )}
      </div>
    </div>
  );
}
